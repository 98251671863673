const checkStrVar = (variable: string | undefined, name: string): string => {
  if (!variable) throw new Error(`undefined variable: ${name}`);
  return variable;
};

const BACKEND_URL = checkStrVar(
  process.env.REACT_APP_BACKEND_URL,
  'REACT_APP_BACKEND_URL',
);
const BACKEND_URL_BASE = checkStrVar(
  process.env.REACT_APP_BACKEND_URL_BASE,
  'REACT_APP_BACKEND_URL_BASE',
);

const DEMO_URL = checkStrVar(
  process.env.REACT_APP_DEMO_URL,
  'REACT_APP_DEMO_URL',
);

enum PATHS {
  LOGIN = '/login',
  CREDENTIAL_ORDER = '/credential-order',
  CREDENTIAL_PROJECTIONS = '/credential-projections',
  CREDENTIAL = '/credential',
  USER_CREDENTIAL = '/user/credential',
  CREDENTIAL_TYPES = '/credential-types',
  ISSUE_CREDENTIAL = '/ivm-auth',
  IDENTITY_VERIFICATION_METHOD = '/ivm',
  USER_CREDENTIALS = '/user/credentials',
  USER_CREATE_CREDENTIAL = '/user/create-credential',
  ENTITY_LIST = '/entities',
  TOKEN = '/token',
  RESEND_EMAIL = '/credential/resend-email',
  USER_RESEND_EMAIL = '/user/credential/resend-email',
  REVOKE_CREDENTIAL = '/revocation',
  USER_REVOKE_CREDENTIAL = '/user/revocation',
  AUDIT_TRAILS = '/audit-trails',
  CREDENTIAL_REQUESTS = '/credential-requests',
  DEVICE_LIST = '/sign-device-list',
  DOCUMENT_STATUS = '/sign-document-status',
  ENTITIES = '/entities',
  WALLETS = '/wallets',
  GET_WALLETS = '/entities/:entityId/wallets',
  DELETE_WALLET = '/entities/:entityId/wallets/:walletId',
  KEY_MANAGEMENT_SERVICE = '/keyManagementService/keystorages',
  GENERATE_DID_DOCUMENT = '/did/:did/didDocument',
  CREATE_DID = '/wallet/:walletId/did',
  ACTIVATE_DID = '/wallet/:walletId/did/:did/activate',
  EDIT_DID = '/wallet/:walletId/did/:did',
  DELETE_DID = '/wallet/:walletId/did/:did',
}

const OPENID = {
  CLIENT_ID: process.env.REACT_APP_OPENID_CLIENT_ID,
  IDENTITY_PROVIDER: process.env.REACT_APP_IDENTITY_PROVIDER,
  REDIRECT_CALLBACK: process.env.REACT_APP_REDIRECT_CALLBACK,
  REDIRECT_CALLBACK_LOGIN: process.env.REACT_APP_REDIRECT_CALLBACK_LOGIN,
};

enum FRONTEND_PATHS {
  HOME = '/',
  LOGIN = '/login',
  CREDENTIALS = '/credentials',
  ISSUE_CREDENTIALS = '/issue-credentials',
  USER_CREDENTIALS = '/user-credentials',
  CREDENTIAL_REQUESTS = '/credential-requests',
  AUDIT_TRAILS = '/audit-trails',
  EMAIL_LOGIN = '/email-login/:credentialRequestId',
  CALLBACK = '/callback',
  LOGIN_CALLBACK = '/login-callback',
  ENTITIES_MANAGEMENT = '/entities-management/',
  CREATE_ENTITY = 'create-entity',
  ENTITY_DETAILS = 'entity-details/:entityId',
  WALLET_DETAILS = 'entity-details/:entityId/wallet-details/:walletId',
  CREATE_WALLET = 'entity-details/:entityId/create-wallet',
  CREATE_DID = 'entity-details/:entityId/wallet-details/:walletId/create-did',
}

export {
  BACKEND_URL,
  PATHS,
  FRONTEND_PATHS,
  OPENID,
  BACKEND_URL_BASE,
  DEMO_URL,
};
