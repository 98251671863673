import './Login.css';
import React from 'react';
import { OpenIDClient } from '../../services/openIDClient';
import VIDidentityLogo from '../../assets/vidcredentials-logo.svg';
import google from './../../assets/google.svg';
import apple from './../../assets/apple.svg';
import hero from './../../assets/login-background.svg';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import { FeatureHub } from 'featurehub-javascript-client-sdk';
import { UserAuth } from '../../domain/userAuth';
import { useTranslation } from 'react-i18next';
import { DEMO_URL } from '../../config';

export function Login() {
  const { t } = useTranslation();

  const signInWithVIDchain = async () => {
    const client = OpenIDClient.getInstance().getLoginClient();
    client.wipeTokens();
    await client.callback();
    const token = await client.getToken({
      scopes: {
        request: ['openid', 'VIDCredentialStudio'],
      },
    });
    const user = new UserAuth(token);
    await FeatureHub.context
      .attributeValue('role', user.getEntityName().toLowerCase())
      .build();
  };

  return (
    <div className="blue-login">
      <header className="login-header">
        <img src={VIDidentityLogo} alt="VIDidentity logo" />
      </header>
      <section className="language-selector-wrapper">
        <LanguageSelector />
      </section>
      <section className="home-container">
        <div className="info-column">
          <h1>{t('login.control')}</h1>
          <p>{t('login.with_vidchain')}</p>
          <div className="vid-chain-buttons-container">
            <button
              className="new-sign-in-vid-chain"
              onClick={() => signInWithVIDchain()}
            >
              <span>{t('login.login_with')}</span>
              <span>&rarr;</span>
            </button>
            <a
              className="new-sign-in-vid-chain"
              href={DEMO_URL}
              target="_blank"
            >
              <span>{t('login.more_info')}</span>
              <span>&rarr;</span>
            </a>
          </div>
        </div>
        <div className="background-column">
          <img src={hero} alt="Background" />
        </div>
      </section>
      <div className="download-container">
        <span>{t('login.get_it')}:</span>
        <div className="apps-container">
          <a
            href="https://play.google.com/store/apps/details?id=com.validatedid.wallet&hl=es_VE&gl=US"
            target="_blank"
          >
            <img src={google} alt="App" />
          </a>
          <a
            href="https://apps.apple.com/es/app/vidwallet/id1554340592"
            target="_blank"
          >
            <img src={apple} alt="App" />
          </a>
        </div>
      </div>
    </div>
  );
}
