import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Login } from './pages/Login/Login';
import { EmailLogin } from './pages/EmailLogin/EmailLogin';
import { Callback } from './pages/Callback/Callback';
import { LoginCallback } from './pages/LoginCallback/LoginCallback';
import { IssueCredentialForm } from './components/IssueCredentialForm/IssueCredentialForm';
import { Credentials } from './pages/Credentials/Credentials';
import { UserCredentials } from './pages/UserCredentials/UserCredentials';
import { AuditTrails } from './pages/AuditTrails/AuditTrails';
import { UserCredentialsContextProvider } from './contexts/CredentialsProviders/Providers/UserCredentialsContext';
import { AuthContext } from './contexts/AuthContext';
import { CredentialRequests } from './pages/CredentialRequests/CredentialRequests';
import { CredentialRequestsContextProvider } from './contexts/CredentialsProviders/Providers/CredentialRequestsContext';
import { createTheme, ThemeProvider } from '@mui/material';
import { AllCredentialsContextProvider } from './contexts/CredentialsProviders/Providers/AllCredentialsContext';
import { AuditTrailsContextProvider } from './contexts/CredentialsProviders/Providers/AuditTrailsContext';
import EntitiesManagement from './pages/EntitiesManagement/EntitiesManagement/EntitiesManagement';
import HeaderWithLateralMenu from './contexts/HeaderWithLateralMenu';
import CreateEntity from './pages/EntitiesManagement/CreateEntity';
import EntityDetails from './pages/EntitiesManagement/EntityDetails/EntityDetails';
import { MenuContextProvider } from './contexts/MenuContext';
import { EntitiesContextProvider } from './contexts/EntitiesContext';
import { FRONTEND_PATHS } from './config';
import CreateWallet from './pages/EntitiesManagement/CreateWallet';
import WalletDetails from './pages/EntitiesManagement/WalletDetails/WalletDetails';
import CreateDid from './pages/EntitiesManagement/CreateDid';

function App() {
  const { accessToken } = useContext(AuthContext);
  const defaultMaterialTheme = createTheme();

  return (
    <MenuContextProvider>
      <ThemeProvider theme={defaultMaterialTheme}>
        <BrowserRouter>
          <Routes>
            {!accessToken && (
              <Route path={FRONTEND_PATHS.LOGIN} element={<Login />} />
            )}
            <Route
              path={FRONTEND_PATHS.HOME}
              element={
                <AllCredentialsContextProvider>
                  <HeaderWithLateralMenu />
                </AllCredentialsContextProvider>
              }
            >
              <Route
                path={FRONTEND_PATHS.CREDENTIALS}
                element={<Credentials />}
              />
              <Route
                path={FRONTEND_PATHS.ISSUE_CREDENTIALS}
                element={<IssueCredentialForm />}
              />
              <Route
                path={FRONTEND_PATHS.USER_CREDENTIALS}
                element={
                  <UserCredentialsContextProvider>
                    <UserCredentials />
                  </UserCredentialsContextProvider>
                }
              />
              <Route
                path={FRONTEND_PATHS.CREDENTIAL_REQUESTS}
                element={
                  <CredentialRequestsContextProvider>
                    <CredentialRequests />
                  </CredentialRequestsContextProvider>
                }
              />
              <Route
                path={FRONTEND_PATHS.AUDIT_TRAILS}
                element={
                  <AuditTrailsContextProvider>
                    <AuditTrails />
                  </AuditTrailsContextProvider>
                }
              />
              <Route
                path={FRONTEND_PATHS.HOME}
                element={
                  <Navigate
                    to={
                      accessToken
                        ? FRONTEND_PATHS.CREDENTIALS
                        : FRONTEND_PATHS.LOGIN
                    }
                    replace
                  />
                }
              />
            </Route>
            <Route path={FRONTEND_PATHS.EMAIL_LOGIN} element={<EmailLogin />} />
            <Route path={FRONTEND_PATHS.CALLBACK} element={<Callback />} />
            <Route
              path={FRONTEND_PATHS.LOGIN_CALLBACK}
              element={<LoginCallback />}
            />
            <Route
              path={FRONTEND_PATHS.ENTITIES_MANAGEMENT}
              element={
                <EntitiesContextProvider>
                  <HeaderWithLateralMenu />
                </EntitiesContextProvider>
              }
            >
              <Route path="" element={<EntitiesManagement />} />
              <Route
                path={FRONTEND_PATHS.ENTITY_DETAILS}
                element={<EntityDetails />}
              />
              <Route
                path={FRONTEND_PATHS.WALLET_DETAILS}
                element={<WalletDetails />}
              />
              <Route
                path={FRONTEND_PATHS.CREATE_ENTITY}
                element={<CreateEntity />}
              />
              <Route
                path={FRONTEND_PATHS.CREATE_WALLET}
                element={<CreateWallet />}
              />
              <Route path={FRONTEND_PATHS.CREATE_DID} element={<CreateDid />} />
            </Route>
            <Route
              path="*"
              element={
                <Navigate
                  to={
                    accessToken
                      ? FRONTEND_PATHS.CREDENTIALS
                      : FRONTEND_PATHS.LOGIN
                  }
                  replace
                />
              }
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </MenuContextProvider>
  );
}

export default App;
