import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { CreateCredentialOrderDto } from '../../domain/createCredentialOrderDto';
import { CredentialTypePrimitives } from '../../domain/credentialTypes';
import { LevelOfAssuranceDto } from '../../domain/levelOfAssurance';
import {
  createCredentialOrder,
  getCredentialTypes,
  getDevicesList,
  ParsedBackendError,
} from '../../services/vidcredentials';
import { getLevelOfAssurances } from '../../services/levelOfAssurance';
import { CredentialTemplate } from '../CredentialTemplate/CredentialTemplate';
import { LoATemplate } from '../LoATemplate/LoATemplate';
import { useForm } from 'react-hook-form';
import './IssueCredentialForm.css';
import { MenuContext } from '../../contexts/MenuContext';
import { Popup } from '../Popup/Popup';
import { useRouteSecurization } from '../../hooks/useRouteSecurization';
import { Device } from '../../domain/devices';
import { useTranslation } from 'react-i18next';
import { useAccessToken } from '../../hooks/useRefreshToken';

export const IssueCredentialForm = () => {
  const { t } = useTranslation();
  const { isMenuOpen } = useContext(MenuContext);
  const [credentialTypes, setCredentialType] = useState<
    CredentialTypePrimitives[]
  >([]);
  const [levelOfAssurances, setLevelOfAssurance] = useState<
    LevelOfAssuranceDto[]
  >([]);
  const [credentialTypeSelected, setCredentialTypeSelected] = useState('');
  const [levelOfAssuranceSelected, setLevelOfAssuranceSelected] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [devicesList, setDevicesList] = useState<Device[]>([]);

  useEffect(() => {
    const loadDevices = async (): Promise<void> => {
      if (levelOfAssuranceSelected === 'F2F') {
        const devicesList = await getDevicesList();
        setDevicesList(devicesList as Device[]);
      }
    };
    loadDevices();
  }, [levelOfAssuranceSelected]);

  useRouteSecurization(['admin', 'operator']);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const fetchCredentialTypes = async () => {
    const credentialTypes = await getCredentialTypes();
    if ('error' in credentialTypes) {
      return;
    }
    const levelOfAssurances = await getLevelOfAssurances();
    setCredentialType(credentialTypes);
    setLevelOfAssurance(levelOfAssurances as LevelOfAssuranceDto[]);
    setCredentialTypeSelected(
      credentialTypes.length > 0 && credentialTypes[0].id
        ? credentialTypes[0].id
        : '',
    );
    setLevelOfAssuranceSelected(
      levelOfAssurances.length > 0 ? levelOfAssurances[0].type : '',
    );
  };

  useAccessToken(fetchCredentialTypes);

  const handleCredentialTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCredentialTypeSelected(event.target.value);
  };

  const handleLevelOfAssuranceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLevelOfAssuranceSelected(event.target.value);
  };

  const searchCredentialTypeTemplate = (id: string) => {
    const credentialType = credentialTypes.find(
      (credentialType) => credentialType.id === id,
    );
    return credentialType ? credentialType.template : [];
  };

  const searchLevelOfAssuranceTemplate = (type: string) => {
    const levelOfAssurance = levelOfAssurances.find(
      (levelOfAssurance) => levelOfAssurance.type === type,
    );
    let fields: string[] = [];
    if (levelOfAssurance && levelOfAssurance.requestedInfoPaths) {
      fields = fields.concat(levelOfAssurance.requestedInfoPaths as string[]);
    }
    if (levelOfAssurance && levelOfAssurance.userDataFields) {
      fields = fields.concat(levelOfAssurance.userDataFields as string[]);
    }
    return fields;
  };

  const isIsoDate = (str: any): boolean => {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    const date = new Date(str);
    return !Number.isNaN(date) && date.toISOString() === str;
  };

  const isNumeric = (str: any): boolean => {
    if (typeof str !== 'string') return false;
    if (!/^-?\d*(\.\d+)?$/.test(str)) return false;

    const number = parseFloat(str);
    return Number.isFinite(number);
  };

  const checkIfBool = (value: any) => {
    return value === 'true' || value === 'false';
  };

  const getCredentialSubject = (inputList: any[]) => {
    const credentialSubject: { [key: string]: number | string | boolean } = {};
    for (let index = 0; index < inputList.length; index++) {
      if (inputList[index].id.includes(credentialTypeSelected)) {
        if (inputList[index].value === '') {
          continue;
        }
        credentialSubject[inputList[index].name] = checkIfBool(
          inputList[index].value,
        )
          ? JSON.parse(inputList[index].value)
          : inputList[index].value;
      }
      if (isIsoDate(inputList[index].value)) {
        credentialSubject[inputList[index].name] = new Date(
          inputList[index].value,
        ).toISOString();
      }
      if (isNumeric(inputList[index].value)) {
        credentialSubject[inputList[index].name] = Number(
          inputList[index].value,
        );
      }
    }
    return credentialSubject;
  };

  const buildRequestedInfo = (inputList: any[]) => {
    const requestedInfo: { path: string; value: string }[] = [];
    for (let index = 0; index < inputList.length; index++) {
      if (inputList[index].id.includes(levelOfAssuranceSelected)) {
        requestedInfo.push({
          path:
            inputList[index].name === 'emailPath'
              ? 'email'
              : inputList[index].name,
          value: inputList[index].value,
        });
      }
    }
    return requestedInfo;
  };

  const buildUserData = (inputList: any[]) => {
    const userData: any = {};
    for (let index = 0; index < inputList.length; index++) {
      if (inputList[index].id.includes(levelOfAssuranceSelected)) {
        userData[inputList[index].name] = inputList[index].value as string;
      }
    }
    return userData;
  };

  const confirm = async (values: any, e: any) => {
    e.preventDefault();
    const email = e.target.receiverEmail.value;
    const credentialSubject: { [key: string]: number | string | boolean } =
      getCredentialSubject(e.target);

    const createCredentialOrderDto: CreateCredentialOrderDto = {
      typeId: credentialTypeSelected,
      email: email,
      payload: credentialSubject,
      identityVerificationMethod: {
        type: levelOfAssuranceSelected,
      },
    };

    if (levelOfAssuranceSelected !== 'F2F') {
      createCredentialOrderDto.identityVerificationMethod.requestedInfo =
        buildRequestedInfo(e.target);
    }
    if (levelOfAssuranceSelected === 'F2F') {
      createCredentialOrderDto.identityVerificationMethod.userData =
        buildUserData(e.target);
    }

    try {
      await createCredentialOrder(createCredentialOrderDto, values.language);
      createCredentialOrderDto.identityVerificationMethod.type.indexOf(
        'F2F',
      ) !== -1
        ? setSuccessMessage(
            t('form.credentialF2FCreated').replace(
              '%%%%%',
              createCredentialOrderDto.identityVerificationMethod.userData
                ?.deviceId || '',
            ),
          )
        : setSuccessMessage(t('form.credentialCreated'));
      setErrorMessage('');
    } catch (e) {
      setErrorMessage((e as ParsedBackendError).title);
      setSuccessMessage('');
    }
    resetForm(e);
  };

  const resetForm = async (e: any) => {
    e.target.receiverEmail.value = '';
    e.target.levelOfAssurance.value = '';
    e.target.credentialType.value = '';
    for (let index = 0; index < e.target.length; index++) {
      e.target[index].value = '';
    }
  };

  const { ref, ...rest } = register('originalEmail', {
    required: t('form.fillfield'),
    pattern: {
      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      message: t('form.provideValidEmail'),
    },
  });

  const { ref: languageRef, ...languageRest } = register('language', {
    required: t('form.fillfield'),
  });

  return (
    <div className={`formDiv ${isMenuOpen ? 'open-lateral-menu' : ''}`}>
      <Form onSubmit={handleSubmit(confirm)} noValidate>
        <h1>{t('form.fill')}</h1>
        <FormGroup>
          <Label for="receiverEmail">{t('form.receiverEmail')}</Label>
          <Input
            id="receiverEmail"
            placeholder="Email"
            innerRef={ref}
            {...rest}
          />
          {errors.originalEmail && (
            <span className="error-span">{errors.originalEmail.message}</span>
          )}
        </FormGroup>
        <hr />
        <FormGroup>
          <Label for="credentialType">{t('filters.credentialType')}</Label>
          <Input
            id="credentialType"
            name="select"
            type="select"
            onChange={handleCredentialTypeChange}
          >
            {credentialTypes.map((credentialType) => (
              <option key={credentialType.id} value={credentialType.id}>
                {credentialType.type[credentialType.type.length - 1]}
              </option>
            ))}
          </Input>
        </FormGroup>
        {credentialTypeSelected ? (
          <CredentialTemplate
            credentialTypeId={credentialTypeSelected}
            template={searchCredentialTypeTemplate(credentialTypeSelected)}
            register={register}
            errors={errors}
          />
        ) : (
          <div>{t('form.loadingCredential')}</div>
        )}
        <hr />
        <FormGroup>
          <Label for="levelOfAssurance">{t('form.identityVerification')}</Label>
          <Input
            id="levelOfAssurance"
            name="select"
            type="select"
            onChange={handleLevelOfAssuranceChange}
          >
            {levelOfAssurances.map((levelOfAssurance) => (
              <option key={levelOfAssurance.type} value={levelOfAssurance.type}>
                {levelOfAssurance.type}
              </option>
            ))}
          </Input>
        </FormGroup>
        {levelOfAssuranceSelected && (
          <LoATemplate
            type={levelOfAssuranceSelected}
            devicesList={devicesList}
            paths={searchLevelOfAssuranceTemplate(levelOfAssuranceSelected)}
            register={register}
            errors={errors}
          />
        )}
        {levelOfAssuranceSelected && !devicesList && (
          <div>{t('form.loadingIdentity')}</div>
        )}
        <FormGroup>
          <Label for="language">{t('form.userLanguage')}</Label>
          <Input
            id="language"
            type="select"
            innerRef={languageRef}
            {...languageRest}
          >
            <option value="">{t('form.chooseLanguage')}</option>
            <option value="es">{t('language.spanish')}</option>
            <option value="en">{t('language.english')}</option>
          </Input>
          {errors.language && (
            <span className="error-span">{errors.language.message}</span>
          )}
        </FormGroup>
        <Button className="buttonConfirm">{t('actions.confirm')}</Button>
        {errorMessage && (
          <Popup
            error
            message={errorMessage}
            onClose={() => setErrorMessage('')}
          />
        )}
        {successMessage && (
          <Popup
            success
            message={successMessage}
            onClose={() => setSuccessMessage('')}
          />
        )}
      </Form>
    </div>
  );
};
